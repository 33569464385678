import { useEffect, useState } from "react";
import Player from "@vimeo/player";
import DATAS from "./data";

import { LazyLoadImage } from "react-lazy-load-image-component";

function Home() {
  const [videoPlayed, setVideoPlayed] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");

  useEffect(() => {
    setVideoPlayed(false);
  }, [selectedVideo]);

  useEffect(() => {
    if (videoPlayed) {
      const iframe = document.querySelector("iframe");
      const player = new Player(iframe);

      player
        .ready()
        .then(() => {
          player.play().catch((error) => {
            // might be used to handle interrupting play error on early pause clicks
            //console.error("Error playing video:", error);
          });
        })
        .catch((error) => {
          // console.error("Error loading video:", error);
        });
    }
  }, [videoPlayed]);

  const navigateVideos = (direction) => {
    if (selectedVideo) {
      const i = DATAS.findIndex((o) => o.id === selectedVideo.id);
      if (direction === "next" && DATAS[i + 1]) {
        setSelectedVideo(DATAS[i + 1]);
      } else if (direction === "prev" && DATAS[i - 1]) {
        setSelectedVideo(DATAS[i - 1]);
      }
    }
  };

  const isFirst = () => {
    if (selectedVideo) {
      const i = DATAS.findIndex((o) => o.id === selectedVideo.id);
      return i === 0;
    }
    return false;
  };

  const isLast = () => {
    if (selectedVideo) {
      const i = DATAS.findIndex((o) => o.id === selectedVideo.id);
      return i === DATAS.length - 1;
    }
  };

  return (
    <div className="elements-wrapper">
      {selectedVideo && (
        <div className="selected-video">
          <div className="video-part">
            <div className={`video-img-frame`}>
              <div
                className={`video-iframe`}
                style={{ padding: "56.25% 0 0 0", position: "relative" }}
              >
                <iframe
                  src={`https://player.vimeo.com/video/${selectedVideo.id}`}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  allow="autoplay"
                  frameborder="none"
                  allowFullScreen
                  //; fullscreen; picture-in-picture"
                  //allow={videoPlayed ? "autoplay" : ""}
                  // allowfullscreen
                ></iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>

              <div
                className={`video-image ${videoPlayed ? "video-played" : ""}`}
              >
                <img src={`/assets/${selectedVideo.imgSrc}`}></img>
                <div className="play-icon">
                  <img
                    onClick={() => {
                      setVideoPlayed(true);
                    }}
                    src="/assets/play-icon-50x50.png"
                    alt="play"
                  ></img>
                </div>
              </div>
            </div>
            <div className="controls">
              <p
                className={`${isFirst() ? "disabled" : ""}`}
                onClick={() => navigateVideos("prev")}
              >
                prev
              </p>
              <p className="title">{selectedVideo.title}</p>
              <p
                className={`${isLast() ? "disabled" : ""}`}
                onClick={() => navigateVideos("next")}
              >
                next
              </p>
            </div>
          </div>
          <div className="explanation-part">
            <h2 className="video-title">{selectedVideo.title}</h2>
            {selectedVideo.explanations.map((o) => (
              <p>{o}</p>
            ))}
          </div>
        </div>
      )}
      {/* 750den aşağısı 2 görsel ve menü yukarda */}
      <div className="gallery">
        {DATAS.map((o, i) => (
          <div
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });

              setSelectedVideo(o);
            }}
            className="gallery-item"
            key={i}
          >
            <img
              src={`/assets/${o.imgSrc}`}
              // PlaceholderSrc={`/assets/lowRes/${o.imgSrc}`}
            ></img>
            <div className="image-description">
              <p>{o.title}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Home;
