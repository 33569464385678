import { Outlet, Link, useLocation } from "react-router-dom";

function Layout() {
  const location = useLocation();

  return (
    <div className="main-wrapper">
      {/* A "layout route" is a good place to put markup you want to
            share across all the pages on your site, like navigation. */}
      <nav className="navbar">
        <Link to="/">
          <div className="logo" style={{ color: "black" }}>
            <h2>EYMEN UZUNKÖK</h2>
            <h3 style={{ color: "#a1a1a1" }}>FILMMAKER</h3>
            {/* #a1a1a1  */}
          </div>
        </Link>

        <div className="menu">
          <ul>
            <li>
              <Link
                className={`${location.pathname === "/" ? "active" : ""}`}
                to="/"
              >
                SELECTED WORK
              </Link>
            </li>
            <li>
              <Link
                className={`${
                  location.pathname === "/contact" ? "active" : ""
                }`}
                to="/contact"
              >
                ABOUT
              </Link>
            </li>
          </ul>
        </div>
      </nav>

      {/* An <Outlet> renders whatever child route is currently active,
            so you can think about this <Outlet> as a placeholder for
            the child routes we defined above. */}
      <Outlet />

      <div className="footer">
        <div className="icons-wrapper">
          <a href="https://www.instagram.com/eymenuzunkok/" target="_blank">
            <img src="/assets/instagram-icon-50x50.png" alt="instagram"></img>
          </a>
          <a href="https://vimeo.com/eymenuzunkok" target="_blank">
            <img src="/assets/vimeo-icon-50x50.png" alt="vimeo"></img>
          </a>
          <a href="https://www.linkedin.com/in/eymenuzunkok" target="_blank">
            <img src="/assets/linkedin-icon-50x50.png" alt="linkedin"></img>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Layout;
