import { LazyLoadImage } from "react-lazy-load-image-component";

function Contact() {
  return (
    <>
      <div className="contact-page-wrapper">
        <div className="landscape-wrapper">
          <img
            src="/assets/Studyo.jpg"
            alt="Eymen Uzunkök Portrait"
            // placeholderSrc="/assets/lowRes/Studyo.jpg"
          ></img>
        </div>
        <div className="contact-wrapper">
          <div>
            <p className="paragraph">
              Hi there! I'm Eymen. My career began with my interest in my
              father's work as a journalist and writer, leading me to choose a
              journalism high school. Since I have always loved telling visual
              stories, I followed my teacher's advice during high school and
              took the talent exams for the Photography Department at Akdeniz
              University in Antalya, which I passed.
            </p>
            <br />
            <p className="paragraph">
              During my education, I embraced a documentary perspective and
              completed my first documentary in one year, focusing on the lives
              of homeless people I observed in Ankara. Until I graduated, I
              directed numerous European Union projects and documentaries that
              were broadcast on TV.
            </p>
            <br />
            <p className="paragraph">
              After completing my photography and video education, I started
              producing projects in various promotional fields, such as visual
              storytelling, architectural photography, food and beverage
              visualization, tourism, and lifestyle, by working in different
              countries. I continue to direct promotional films for many brands
              under ACCOR, one of the world's largest hospitality companies,
              including Raffles, Rixos, Sofitel, M Gallery, Novotel, Mövenpick,
              and Pullman, across various countries.
            </p>
            <br />
            <p className="paragraph">
              National Broadcasts
              <p>"Kısırlaştır, Aşılat, Yaşat" PSAs RTUK (2023)</p>
              <p>
                "Ancient Cities for Endemic Flora, Apollo to Athena" TRT
                Documentary (2021)
              </p>
              <p>
                {" "}
                "Termessos and Autumn Crocusi" European Union Project (2021)
              </p>
              <p>"Doğayı Koru Antalya" European Union Project (2021)</p>
              <p>
                "Phaselis; Dance of Nature, Flower and Stone" EU Project (2020)
              </p>
              <p>"Ministry of Interior EGM Pandemic Process" (2019)</p>
              <p>"Otogar Misafirleri"(2017) Documentary</p>
            </p>
          </div>
          {/* <p>Personal: leonbrehony@gmail.com</p> */}
        </div>
      </div>
      <div style={{ margin: "0 auto", marginTop: "2rem" }}>
        <img className="logos" src="./assets/logolarust.jpg"></img>
        <img className="logos" src="./assets/logolaralt.jpg"></img>
      </div>
      <div className="contact-footer">
        <p>Contact</p>
        <a className="maillink" href="mailto:info@eymenuzunkok.com">
          info@eymenuzunkok.com
        </a>
      </div>
    </>
  );
}

export default Contact;
