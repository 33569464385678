const DATAS = [
  {
    id: "928579268",
    title: "Rixos UAE Hotels",
    imgSrc: "Rixos UAE Hotels.jpg",
    explanations: [
      "Dir. Eymen Uzunkök",
      "Client: Accor & Rixos",
      "Agency: Lens Films Dubai",
    ],
  },
  {
    id: "954443946",
    title: "Rixos Borovoe",
    imgSrc: "Rixos_Borovoe.jpg",
    explanations: [
      "Dir. Eymen Uzunkök",
      "Client: Accor & Rixos Borovoe",
      "Agency: Lens Films Dubai",
    ],
  },
  {
    id: "902483311",
    title: "MGallery The Bodrum",
    imgSrc: "MGallery Bodrum Yalikavak.jpg",
    explanations: [
      "Dir. Eymen Uzunkök",
      "Client: Accor & MGallery The Bodrum",
      "Agency: Lens Films Dubai",
    ],
  },
  {
    id: "954456404",
    title: "Sofitel Bahrain",
    imgSrc: "Sofitel Bahreyn.jpg",
    explanations: [
      "Dir. Eymen Uzunkök",
      "Client: Accor & Sofitel Bahrain",
      "Agency: Lens Films Dubai",
    ],
  },
  {
    id: "804371610",
    title: "Rixos Premium Bodrum",
    imgSrc: "Rixos Premium Bodrum.jpg",
    explanations: [
      "Dir. Eymen Uzunkök",
      "Client: Accor & Rixos",
      "Agency: Lens Films Dubai",
    ],
  },
  {
    id: "972060796",
    title: "Rixos Premium Dubrovnik",
    imgSrc: "Rixos Dubrovnik.jpg",
    explanations: [
      "Dir. Eymen Uzunkök",
      "Client: Accor & Rixos",
      "Agency: Lens Films Dubai",
    ],
  },
  // first 6 done
  {
    id: "804424556",
    title: "Rixos Premium Saadiyat",
    imgSrc: "Rixos Premium Saadiyat Island.jpg",
    explanations: [
      "Dir. Eymen Uzunkök",
      "Client: Accor & Rixos",
      "Agency: Lens Films Dubai",
    ],
  },
  {
    id: "804384621",
    title: "Rixos The Palm Dubai",
    imgSrc: "Rixos The Palm Dubai.jpg",
    explanations: [
      "Dir. Eymen Uzunkök",
      "Client: Accor & Rixos",
      "Agency: Lens Films Dubai",
    ],
  },
  {
    id: "804389753",
    title: "Rixos Premium Dubai JBR",
    imgSrc: "Rixos Premium Dubai JBR.jpg",
    explanations: [
      "Dir. Eymen Uzunkök",
      "Client: Accor & Rixos",
      "Agency: Lens Films Dubai",
    ],
  },
  {
    id: "954434570",
    title: "Raffles Dubai",
    imgSrc: "Raffles Dubai.jpg",
    explanations: [
      "Dir. Eymen Uzunkök",
      "Client: Accor & Raffles Dubai",
      "Agency: Lens Films Dubai",
    ],
  },
  {
    id: "968685840",
    title: "The Sense Hotel",
    imgSrc: "The Sense.jpg",
    explanations: ["Dir. Eymen Uzunkök", "Client: The Sense"],
  },
  {
    id: "804348460",
    title: "Swissotel Makkah, Promotion film",
    imgSrc: "Swissotel Makkah.jpg",
    explanations: [
      "Dir. Eymen Uzunkök",
      "Client: Accor & Swissotel",
      "Agency: Lens Films Dubai",
    ],
  },
  // second 6 done
  {
    id: "954453557",
    title: "Rixos Bab Al Bahr",
    imgSrc: "Rixos Bab Al Bahr.jpg",
    explanations: [
      "Dir. Eymen Uzunkök",
      "Client: Accor & Rixos Bab Al Bahr",
      "Agency: Lens Films Dubai",
    ],
  },
  {
    id: "954447759",
    title: "Rixos Marina Abu Dhabi",
    imgSrc: "Rixos Marina Abu Dhabi.jpg",
    explanations: [
      "Dir. Eymen Uzunkök",
      "Client: Accor & Rixos Marina",
      "Agency: Lens Films Dubai",
    ],
  },
  {
    id: "971506938",
    title: "Mercure Grand Hotel Bahrain",
    imgSrc: "Mercure Grand Hotel Bahrain.jpg",
    explanations: [
      "Dir. Eymen Uzunkök",
      "Client: Accor & Mercure Hotel",
      "Agency: Lens Films Dubai",
    ],
  },
  {
    id: "804354259",
    title: "Ibis Jeddah Malik Road",
    imgSrc: "ibis Jeddah Malik Road.jpg",
    explanations: [
      "Dir. Eymen Uzunkök",
      "Client: Accor & Ibis",
      "Agency: Lens Films Dubai",
    ],
  },
  {
    id: "969005174",
    title: "Fiin College, Istanbul 2024",
    imgSrc: "Fiin College, Istanbul 2024.jpg",
    explanations: ["Dir. Eymen Uzunkök", "Client: Fiin College"],
  },
  {
    id: "968995842",
    title: "Fiin College, Istanbul 2023",
    imgSrc: "Fiin 2023.jpg",
    explanations: ["Dir. Eymen Uzunkök", "Client: Fiin College"],
  },
  // third 6 done
  {
    id: "972174237",
    title: "Maison Bohem Hotel İzmir",
    imgSrc: "Maison Bohem Hotel izmir.jpg",
    explanations: [
      "Dir. Eymen Uzunkök",
      "Client: Maison Bohem Hotel",
      "Agency: MEF Creative",
    ],
  },
  {
    id: "972175132",
    title: "Maison L’etro Alacati Hotel",
    imgSrc: "Maison Letro Alacati Hotel.jpg",
    explanations: [
      "Dir. Eymen Uzunkök",
      "Client: Maison L’etro",
      "Agency: MEF Creative",
    ],
  },
  {
    id: "804350210",
    title: "Mövenpick Makkah",
    imgSrc: "Movenpick Makkah.jpg",
    explanations: [
      "Dir. Eymen Uzunkök",
      "Client: Accor & Mövenpick",
      "Agency: Lens Films Dubai",
    ],
  },
  {
    id: "972210739",
    title: "Swissôtel Al Maqam Makkah",
    imgSrc: "Swissotel Al Maqam Makkah.jpg",
    explanations: [
      "Dir. Eymen Uzunkök",
      "Client: Accor & Swissôtel",
      "Agency: Lens Films Dubai",
    ],
  },
  {
    id: "804351945",
    title: "Novotel Thakher Makkah",
    imgSrc: "Novotel Thakher Makkah Hotel.jpg",
    explanations: [
      "Dir. Eymen Uzunkök",
      "Client: Accor & Novotel",
      "Agency: Lens Films Dubai",
    ],
  },
  {
    id: "432246405",
    title: "Turkish General Directorate of Security - Pandemic Period",
    imgSrc: "EGM-Turkish General Directorate of Security - Pandemic Period.jpg",
    explanations: [
      "TR",
      "Sokağa çıkma yasağında çalıştığım belgesel serüvenine, Antalya Emniyetini de dahil edebilmenin gururunu yaşıyorum. Güvenliğimizi sağlarken ailelerinden uzak düşen polislerimiz için, Antalya Emniyet güçlerinin desteği ile emeklerine yakışır bir çalışma yapmak istedik.",
      "EN",
      "I am proud to include the Antalya Police Department in the documentary project I worked on during the curfew. With the support of the Antalya Police forces, we aimed to create a work that honors the efforts of our police officers who are separated from their families while ensuring our safety.",
      "Director: Eymen Uzunkök",
      "Text : Güven Burak Özen",
      "Voiceover: Kalender İlker",
      "Project from General Directorate of Security",
    ],
  },
  // fourth 6 done
  {
    id: "660131189",
    title: "Termessos and Autumn Crocus",
    imgSrc: "Termessos and Autumn Crocus.jpg",
    explanations: [
      "Termessos Ancient City is located within the borders of Antalya Güllük Mountain National Park. Termessos crocus, blooming in autumn, was first discovered in Termessos Ancient City. This species, which attracts attention with its showy flowers, has a very local distribution. It prefers maquis and pine areas. It is one of the 250 endemic plant species found only in Antalya in the world. It is one of the important biological values of the region with its beauty and eye-catching colors when it blooms.",
      "Director : Eymen Uzunkök",
      "Director of Photography: Mehmet Tosun",
      "Text: İ. Gökhan Deniz, Pınar Kınıklı",
      " Project from European Union",
    ],
  },
  {
    id: "660131624",
    title: "Phaselis; Dance of Nature, Flower and Stone",
    imgSrc: "Phaselis_ Dance of Nature, Flower and Stone.jpg",
    explanations: [
      '"Phaselis; Dance of Nature, Flowers and Stone", which we prepared within the scope of the "Ancient Cities from Apollo to Athena & Endemic Flowers" project supported by European Union funds within the scope of Civil Society Dialogue...',
      "Director : Eymen Uzunkök",
      "Director of Photography: Mehmet Tosun",
      "Text: İ. Gökhan Deniz, Pınar Kınıklı",
      "Art Director: Abdullaş Memiş",
      "Dancer: Melike Keskin",
    ],
  },
  {
    id: "972472790",
    title: "“Ancient Cities for Endemic Flora, Apollo to Athena” (2021)",
    imgSrc: "Ancient Cities for Endemic Flora, Apollo to Athena.jpg",
    explanations: [
      "Our documentary is about endemic plants and their history in the ancient cities of Turkey and Greece, where they were first discovered in the world. It took 2 years to shoot and edit and was broadcast on TRT Documentary in 2021.",
    ],
  },
  {
    id: "290048838",
    title: "“Otogar Misafirleri” Documentary",
    imgSrc: "_Otogar Misafirleri_ Documentary.jpg",
    explanations: [
      " Dir. Eymen Uzunkök",
      "The documentary about thousands of homeless people living in the bus terminal in Ankara tells the stories of their lives. It is a journey that took a year to film and edit.",
      'In 2017, I completed long-term projects that touched many lives, including the "Otogar Misafirleri" (2017) social responsibility project, which lasted for 12 months and was about homeless people.',
    ],
  },
  {
    id: "972891218",
    title: "“Kısırlaştır, Aşılat, Yaşat” Kamu Spotu",
    imgSrc: "_Kisirlastir.jpg",
    explanations: [
      "Turkey Awareness project for animals for public service announcement",
      "Association: KITMİR",
    ],
  },
  {
    id: "345057198",
    title: "Monatik Concert",
    imgSrc: "Monatik Concert.jpg",
    explanations: [
      "Dir. Eymen Uzunkök",
      "Client: Monatik & Bellis Hotel",
      "Agency: MEF Creative",
    ],
  },
  // fifth 6 done
  {
    id: "349265572",
    title: "Polina Gagarina Concert",
    imgSrc: "Polina Gagarina Concert.jpg",
    explanations: [
      "Dir. Eymen Uzunkök",
      "Client: Bellis Hotel & Polina Gagarina",
      "Agency: MEF Creative",
    ],
  },
  {
    id: "324374473",
    title: "Manga IF Performance",
    imgSrc: "Manga IF Performance.jpg",
    explanations: [
      "Dir. Eymen Uzunkök",
      "Client: MANGA & IF Performance",
      "Agency: MEF Creative",
    ],
  },
  {
    id: "334298011",
    title: "Sena Şener - Ölsem",
    imgSrc: "Sena Sener - Olsem.jpg",
    explanations: [
      "Dir. Eymen Uzunkök",
      "Client: Sena ŞENER",
      "Agency: MEF Creative",
    ],
  },
  {
    id: "341179991",
    title: "Todes, Dance Group",
    imgSrc: "Todes, Dance Group.jpg",
    explanations: [
      "Dir. Eymen Uzunkök",
      "Client: Todes",
      "Agency: MEF Creative",
    ],
  },
  // {
  //   id: "313341612",
  //   title: "EZHEL - ZRF2018",
  //   imgSrc: "EZHEL - ZRF2018.jpg",
  //   explanations: [
  //     "Dir. Eymen Uzunkök",
  //     "Client: EZHEL",
  //     "Agency: MEF Creative",
  //   ],
  // },
  // DONE
  // başlıkları başlık gibi istiyor
  //   {
  //     id: "804389753",
  //     title: "Rixos Premium Dubai JBR",
  //     imgSrc: "Rixos Premium Dubai JBR, Promotion film.jpeg",
  //     explanations: [
  //       "Director: Eymen Uzunkök",
  //       "Production Company: Lens Films Dubai",
  //       "Producer: Levent Özçelik",
  //       "Cinematographer: Şenol Günel",
  //       "Styling: Serra Akar",
  //       "Assistant: Paul Calabon",
  //       "Client: Accor, Rixos Premium Dubai JBR",
  //       "Thanks: Erdoğancan Kabakçı",
  //     ],
  //   },
  //   {
  //     id: "902483311",
  //     title: "MGallery The Bodrum Hotel",
  //     imgSrc: "MGallery The Bodrum Hotel Promotion Film.jpeg",
  //     explanations: [
  //       "Director : Eymen Uzunkök",
  //       "Founder: Lens Films Dubai",
  //       "Producer: Levent Ozcelik",
  //       "Client: Accor, MGallery Hotel Collection",
  //       "Photographer: Hakan Yeşil",
  //       "Assistant: Habibe Yontar",
  //       // "We worked for a week at the MGallery hotel, where we worked in the fascinating Bodrum nature. The fact that the hotel has artifacts from all over the world added a different atmosphere to our visuals.",
  //       // "Thanks to the amazing team we were able to give our movie an emotional meaning. Thanks to everyone’s hard work.",
  //     ],
  //   },
  //   {
  //     id: "804371416",
  //     title: "Rixos Premium Dubrovnik",
  //     imgSrc: "Rixos Premium Dubrovnik, Promotion film.jpeg",
  //     explanations: [
  //       "Director : Eymen Uzunkök",
  //       "Founder: Lens Films Dubai",
  //       "Producer: Levent Özçelik",
  //       "Client: Accor, Rixos",
  //       "Photographer: Hakan Yeşil",
  //       "Styling: Gizem Özçelik",
  //       // "Set against the stunning backdrop of the Adriatic Sea, Rixos Dubrovnik is a hotel nestled around the historic walls. With its vibrant culture and rich heritage, the hotel’s mysterious beauty added an unforgettable atmosphere to our shoots.",
  //       "Thanks for your help!",
  //       "Şeref FİDAN",
  //     ],
  //   },
  //   {
  //     id: "804384621",
  //     title: "Rixos The Palm Dubai",
  //     imgSrc: "Rixos The Palm Dubai, Promotion film .jpeg",
  //     explanations: [
  //       "Rixos The Palm Dubai Promotion Film",
  //       "Director : Eymen Uzunkök",
  //       "Founder: Lens Films Dubai",
  //       "Producer: Levent Özçelik",
  //       "Photographer: Şenol Günel",
  //       "Styling: Gözde Ulusoy",
  //       "Assistant: Paul Calabon",
  //       "Client: Accor, Rixos The Palm Dubai",
  //       "Thanks for your help!",
  //       "Erdoğancan Kabakçı",
  //     ],
  //   },

  //   {
  //     id: "804424556",
  //     title: "Rixos Premium Saadiyat",
  //     imgSrc: "Rixos Premium Saadiyat, Promotion film .jpeg",
  //     explanations: [
  //       "Director : Eymen Uzunkök",
  //       "Founder: Lens Films Dubai",
  //       "Producer: Levent Özçelik",
  //       "Photographer: Şenol Günel",
  //       "Styling: Serra Akar",
  //       "Assistant: Paul Calabon",
  //       "Client: Accor, Rixos Saadiyat",
  //       // "Set against the stunning backdrop of Abu Dhabi, Rixos Saadiyat is the ultimate destination where first class meets vacation concept. Located on the shores of the Persian Gulf, the hotel offers Club Prive privileges. Thanks to the amazing team we were able to give our movie an emotional meaning. Thanks to everyone’s hard work",
  //       "Thanks for your help!",
  //       "Erdoğancan Kabakçı",
  //     ],
  //   },
  //   {
  //     id: "804371610",
  //     title: "Rixos Premium Bodrum",
  //     imgSrc: "Rixos Premium Bodrum, Promotion film.jpeg",
  //     explanations: [
  //       "Director : Eymen Uzunkök",
  //       "Founder: Lens Films Dubai",
  //       "Producer: Levent Özçelik",
  //       "Photographer: Hakan Yeşil",
  //       "Client: Accor, Rixos",
  //       // "Rixos Bodrum is a unique hotel that encompasses the enchanting beauty and historical texture of Bodrum.",
  //       // "Along with our location scouting and filming, we worked here for a total of 13 days.",
  //       // "Capturing the sunset on camera was a magnificent experience in this picturesque setting.",
  //     ],
  //   },
  //   {
  //     id: "359017213",
  //     title: "Dance in One Breath",
  //     imgSrc: "Dance in One Breath .jpeg",
  //     explanations: [
  //       "Abullah Memiş ve Melike Keskin ile beraber, çekiminden ayrı, montajından ayrı keyif aldığımız bir video çıkardık.",
  //       "Projemize desteklerinden dolayı, Antalya İl Kültür Müdürlüğüne ve Antalya Müzeler Müdürlüğüne teşekkürler...",
  //     ],
  //   },
  //   {
  //     id: "432246405",
  //     title: "Antalya Emniyet Müdürlüğü- Pandemi Süreci",
  //     imgSrc: "Antalya Emniyet Mudurlugu- Pandemi Sureci.jpeg",
  //     explanations: [
  //       "Sokağa çıkma yasağında çalıştığım belgesel serüvenine, Antalya Emniyetini de dahil edebilmenin gururunu yaşıyorum. Güvenliğimizi sağlarken ailelerinden uzak düşen polislerimiz için, Antalya Emniyet güçlerinin desteği ile emeklerine yakışır bir çalışma yapmak istedik.",
  //       "Yönetmen: Eymen Uzunkök",
  //       "Metin : Güven Burak Özen",
  //       "Seslendirme: Kalender İlker",
  //     ],
  //   },
  //   {
  //     id: "349265572",
  //     title: "Polina Gagarina Concert",
  //     imgSrc: "Polina Gagarina Concert.jpeg",
  //     explanations: [
  //       "Polina Gagarina - Concert",
  //       "Director : Eymen Uzunkök",
  //       "Photographer: Abdullah Memiş",
  //     ],
  //   },
  //   {
  //     id: "660131624",
  //     title: "Phaselis; Dance of Nature, Flower and Stone",
  //     imgSrc: "Phaselis; Dance of Nature, Flower and Stone.jpeg",
  //     explanations: [
  //       '"Phaselis; Dance of Nature, Flowers and Stone" ,which we prepared within the scope of the "Ancient Cities from Apollo to Athena & Endemic Flowers" project supported by European Union funds within the scope of Civil Society Dialogue...',
  //       "Director : Eymen Uzunkök",
  //       "Director of Photography: Mehmet Tosun",
  //       "Text: İ. Gökhan Deniz, Pınar Kınıklı",
  //       "Art Director: Abdullaş Memiş",
  //       "Dancer: Melike Keskin",
  //     ],
  //   },
  //   {
  //     id: "660131189",
  //     title: "Termessos and Autumn Crocus",
  //     imgSrc: "Termessos and Autumn Crocus.jpeg",
  //     explanations: [
  //       "Termessos Ancient City is located within the borders of Antalya Güllük Mountain National Park. Termessos crocus, blooming in autumn, was first discovered in Termessos Ancient City. This species, which attracts attention with its showy flowers, has a very local distribution. It prefers maquis and pine areas. It is one of the 250 endemic plant species found only in Antalya in the world. It is one of the important biological values of the region with its beauty and eye-catching colors when it blooms.",
  //       "Director : Eymen Uzunkök",
  //       "Director of Photography: Mehmet Tosun",
  //       "Text: İ. Gökhan Deniz, Pınar Kınıklı",
  //     ],
  //   },
  //   {
  //     id: "347692336",
  //     title: "Cosmetic Product - Sun Brown",
  //     imgSrc: "Cosmetic Product - Sun Brown .jpeg",
  //     explanations: ["Sun Brown / Advertisement Series"],
  //   },
  //   {
  //     id: "347691202",
  //     title: "Cosmetic Product - Sun Brown",
  //     imgSrc: "Cosmetic Product - Sun Brown.jpeg",
  //     explanations: ["Sun Brown / Advertisement Series"],
  //   },
  //   {
  //     id: "804348460",
  //     title: "Swissotel Makkah",
  //     imgSrc: "Swissotel Makkah, Promotion film.jpeg",
  //     explanations: [
  //       "Director : Eymen Uzunkök",
  //       "Founder: Lens Films Dubai",
  //       "Producer: Levent Özçelik",
  //       "Client: Accor, Swissotel",
  //       "Photographer: Hakan Yeşil",
  //     ],
  //   },
  //   {
  //     id: "804354259",
  //     title: "Ibis Jeddah Malik Road",
  //     imgSrc: "Ibis Jeddah Malik Road, Promotion film.jpeg",
  //     explanations: [
  //       "Director : Eymen Uzunkök",
  //       "Founder: Lens Films Dubai",
  //       "Producer: Levent Özçelik",
  //       "Client: Accor, Ibis",
  //       "Photographer: Hakan Yeşil",
  //     ],
  //   },
  //   {
  //     id: "804351945",
  //     title: "Novotel Thakher Makkah",
  //     imgSrc: "Novotel Thakher Makkah, , Promotion film.jpeg",
  //     explanations: [
  //       "Director : Eymen Uzunkök",
  //       "Founder: Lens Films Dubai",
  //       "Producer: Levent Özçelik",
  //       "Client: Accor, Novotel",
  //       "Photographer: Hakan Yeşil",
  //     ],
  //   },
  //   {
  //     id: "804350210",
  //     title: "Mövenpick Makkah",
  //     imgSrc: "Movenpick Makkah, Promotion film.jpeg",
  //     explanations: [
  //       "Mövenpick Makkah Promotion Film",
  //       "Directed by me",
  //       "Founder: Lens Films Dubai",
  //       "Producer: Levent Özçelik",
  //       "Client: Accor, Mövenpick",
  //       "Photographer: Hakan Yeşil",
  //     ],
  //   },
  //   {
  //     id: "928579268",
  //     title: "Rixos UAE Hotels",
  //     imgSrc: "Rixos UAE Hotels.jpeg",
  //     explanations: [],
  //   },
];

export default DATAS;
