import { Routes, Route, Outlet, Link, useLocation } from "react-router-dom";
import "./App.css";
import Layout from "./Layout";
import Home from "./Home";
import Contact from "./Contact";
import NoMatch from "./NoMatch";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="contact" element={<Contact />} />

        {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}

        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
}

export default App;
